/* eslint-disable react/no-array-index-key */
import React from "react"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const SplitIFrame = ({ section, index, ...props }: SectionProps) => {
  
  return (
    <div className={`relative w-full`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto sm:px-6 lg:px-0 h-full">
        {section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
        <div className="w-full h-full md:grid md:grid-cols-2 md:gap-6">
          <div className={`relative w-full h-full ${section.advanced?.class || ``}`}>
            <div className="h-full mx-4 md:mx-12 flex flex-col content-center">{section.blurb2 && <div dangerouslySetInnerHTML={createMarkup(section.blurb2)} />}</div>
            <div className="clear-both"></div>
          </div>

          <div className={`relative w-full h-full ${section.advanced?.class2 || ``}`}>
            <div className="relative mx-4 md:m-0" style={{ paddingTop: `56.25%` }}>
              <iframe
                className="absolute inset-0 w-full h-full findme-temp2"
                src={section.iframe.url}
                title={section.iframe.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="clear-both"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitIFrame
